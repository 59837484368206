
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import DataImport from "@/components/scope/data-import.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import { Pageniation as Table, TableRowsSelect } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const learning = namespace("learning");
const base = namespace("base");
@Component({
  components: { TableEnableFlag, DataImport, SwitchStatus },
  filters: {
    NameListFilter: val => {
      return val ? val.join("/") : "-";
    }
  }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.select-spare-parts"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class LearningList extends Vue {
  @learning.Action getLearnContentSettingPage;
  @learning.Action queryLearnClassList;
  @learning.Action queryUserAccountList;
  @learning.Action releaseLearnContent;
  @learning.Action offShelfLearnContent;
  @learning.Action queryExamSignUserList;
  @base.Action getDictionaryList;
  searchForm = {
    learnContentTitle: "",
    learnContentNumber: "",
    learnContentType: "",
    releaseStatus: "",
    releaseTimeStart: "",
    releaseTimeEnd: "",
    releaseUser: "",
    scopeType: "",
    scopeCode: ""
  };
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  sortForm: {};
  contentList: any[] = [];
  contentClassList: any[] = [];
  userAccountList: any[] = [];
  contentStatusList: any[] = [];
  contentRangeList: any[] = [];
  get searchList() {
    return [
      {
        label: t("v210831.title"),
        type: "input",
        value: "",
        prop: "learnContentTitle"
      },
      {
        label: t("v210831.content-number"),
        type: "input",
        value: "",
        prop: "learnContentNumber"
      },
      {
        label: t("test.category"),
        type: "cascader",
        value: "",
        prop: "learnContentType",
        options: this.contentClassList,
        props: {
          label: "className",
          value: "classCode",
          children: "childrenList",
          emitPath: false
        }
      },
      {
        label: t("v210801.state"),
        type: "select",
        selectList: this.contentStatusList,
        value: "",
        prop: "releaseStatus"
      },
      {
        label: t("v210831.release-date"),
        type: "daterange",
        value: "",
        prop: "releaseTime"
      },
      {
        label: t("v210831.release-scope"),
        type: "cascader",
        value: "",
        prop: "releaseRange",
        options: this.contentRangeList,
        props: {
          label: "dataDesc",
          value: "dataValue"
        }
      },
      {
        label: t("v210831.publisher"),
        type: "select",
        selectList: this.userAccountList,
        value: "",
        prop: "releaseUser"
      }
    ];
  }
  categoryList: any[] = [];
  brandList: any[] = [];
  actionRow: any = {};
  signUserList: any = [];
  showSignPeople: boolean = false;
  handleShowSign(row) {
    this.actionRow = row;
    this.showSignPeople = true;
    this.queryExamSignUserList({
      learnContentCode: row.learnContentCode
    }).then(data => {
      this.signUserList = data.data;
    });
  }
  created() {
    this.init();
  }
  async init() {
    this.loadListData();
    this.getContentClassList();
    this.getUserAccount();
    // 状态字典List
    this.getDictionaryList("release_status").then(data => {
      this.contentStatusList = data;
    });
    // 发布范围字典List
    let list = [
      {
        dataDesc: t("v210831.store-type"),
        dataValue: "storeType",
        children: []
      },
      {
        dataDesc: t("v210831.employee-rank"),
        dataValue: "staffLevel",
        children: []
      }
    ];
    this.getDictionaryList("store_type").then(data => {
      list[0].children = data;
    });
    this.getDictionaryList("staff_level").then(data => {
      list[1].children = data;
    });
    this.contentRangeList = list;
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getLearnContentSettingPage({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.contentList = data.data.list;
      return data;
    });
  }
  @TableRowsSelect.updateMethod
  // 获取分类列表
  getContentClassList() {
    this.queryLearnClassList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.contentClassList = this.addLeafFlag(data.data.list || []);
    });
  }
  addLeafFlag(list) {
    const f = function(list) {
      list.forEach(item => {
        if (!item.childrenList || item.childrenList.length < 1) {
          delete item.childrenList;
        } else {
          f(item.childrenList);
        }
      });
    };
    f(list);
    return list;
  }
  getUserAccount() {
    this.queryUserAccountList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.userAccountList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.firstName;
            item.value = item.userCode;
            return item;
          })
        : [];
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "releaseTime") {
          this.searchForm["releaseTimeStart"] = item.value[0] ? item.value[0] : "";
          this.searchForm["releaseTimeEnd"] = item.value[1] ? item.value[1] : "";
        } else if (item.prop === "releaseRange") {
          this.searchForm["scopeType"] = item.value[0];
          this.searchForm["scopeCode"] = item.value[1];
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    console.log(this.searchForm);
    this.loadListData();
  }
  // 操作
  // 发布
  handleRelease(row) {
    this.releaseLearnContent({ learnContentCode: row.learnContentCode }).then(() => {
      this.$message.success(t("v210831.published-successfully"));
      this.loadListData();
    });
  }
  // 下架
  handleWithdraw(row) {
    this.offShelfLearnContent({ learnContentCode: row.learnContentCode }).then(() => {
      this.$message.success(t("v210831.removed-successfully"));
      this.loadListData();
    });
  }
  // 编辑
  handleEdit(row) {
    this.$router.push("/learning/learning-edit/" + row.learnContentCode);
  }
  // 详情
  handleView(row) {
    this.$router.push("/learning/learning-view/" + row.learnContentCode);
  }
  handleCommand(command) {
    if (command === "edit") {
      this.handleEdit(this.row);
    } else if (command === "copy") {
      this.$router.push(`/learning/learning-add?id=${this.row.learnContentCode}`);
    }
  }
  row = undefined;
  changeMore($event, row) {
    console.log("[ row ]", row);
    this.row = row;
  }
  // ////
}
